@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
  height: 100%;
}

@media only screen and (min-width: 640px) {
  body {
    background-color: #f2f8f9;
  }
}
